<template>
  <section>
    <div class="banner">
      <div class="container">
        <div class="card">
          <h3 v-if="info"><img v-if="info.icon" :src="info.icon" height="24"> <span>{{info.name}}({{info.symbol}})</span></h3>
          <p>
            <span class="copy-text">{{ contract }}</span>
            <img
              class="copy-btn"
              @click="copyAction(contract)"
              src="@/static/img/icon/copy_grey.png"
              width="20"
            />
          </p>
        </div>
      </div>
    </div>
    <article class="container">
      <!-- info -->
      <ul class="layout-two-part" v-if="info">
        <li class="card table-list">
          <h3>{{ $t("overview") }}</h3>
          <ul class="layout-about-item-label ">
            <li>
              <label>{{ $t("TotalSupply") }}</label>
              <p>
                {{ webUtil.formatNumber(info.total_supply, info.decimals) }}
                {{ info.symbol }}
              </p>
            </li>
            <li v-if="info.holders">
              <label>{{ $t("Holders") }}</label>
              <p>{{ webUtil.addCommas(info.holders,0) }}</p>
            </li>
            <li v-if="info.transfers">
              <label>{{ $t("Transfers") }}</label>
              <p>{{  webUtil.addCommas(info.transfers,0) }}</p>
            </li>
          </ul>
        </li>
        <li class="card table-list">
          <h3>{{ $t("moreInfo") }}</h3>
          <ul class="layout-about-item-label">
            <li>
              <label>{{ $t("Contract") }}</label>
              <router-link class="text-blue" :to="'/evm/' +info.contract_address">{{info.contract_address}}</router-link>
            </li>
            <li>
              <label>{{ $t("Decimals") }}</label>
              <p>{{info.decimals}}</p>
            </li>
          </ul>
        </li>
      </ul>
      <main class="card table-list">
        <div class="filter" v-if="a">
          <h3 class="flex-between-center">
            <span>{{$t('FilterByHolder')}}</span>
            <!-- <router-link :to="'/token/'+contract" class="filter-tip">
              <img src="@/static/img/icon/close.png" width="20">
              <span>{{$t('ClearFilterAddress')}}</span>
            </router-link> -->
            <p class="filter-tip" @click="removeFilter">
              <img src="@/static/img/icon/close.png" width="20">
              <span>{{$t('ClearFilterAddress')}}</span>
            </p>
          </h3>
            <router-link :to="'/evm/'+a" class="copy-text">{{a}}</router-link>
            <img
              class="copy-btn"
              @click="copyAction(contract)"
              src="@/static/img/icon/copy_grey.png"
              width="20"
            />
        </div>
        <h3 @click="selectTab('transactions')">
          {{ $t("transactions") }} <span class="text-12">{{ total }}</span>
        </h3>
        <!-- 交易记录 -->
        <TokenTransfer
          :list="txs"
          :contract="contract"
          :self="a"
          :isLoading="txLoading"/>
        <!-- 翻页 -->
        <pagination-view
          :limit="limit"
          :total="total"
          :page="page"
          from="evm"
          @emitPage="getTxs"
        ></pagination-view>
      </main>
    </article>
  </section>
</template>

<script>
import paginationView from "base/pagination";
import TokenTransfer from 'base/token-transfer';
export default {
  props: ["contract","a"],
  data() {
    return {
      txLoading: false,
      info: null,
      txs: null,
      total: 0,
      page: 1,
      selectedToken: "1",
      limit: 10,
      price: 0,
      L1Address:'',
    };
  },
  created() {
    this.init();
  },
  methods: {
    removeFilter(){
      this.$router.push('/token/'+this.contract);
    },
    changeToken(item) {
      this.selectedToken = item;
    },
    init(){
      this.getInfo();
      this.getTxs();
    },
    getInfo() {
      this.axios
        .get(this.domain + "evm/token/" + this.contract)
        .then((res) => {
        this.info = res.data.data;
      }).catch(() => {
        this.info = null;
        console.log("Get info failed");
      });
    },
    getTxs(page = 0) {
      this.page = page+1;
      let offset = this.webUtil.BN(page).times(this.limit).toFixed();
      this.txLoading = true;
      let url = this.domain +
            "evm/token_transfers/" +
            this.contract +
            "?limit=" +
            this.limit +
            "&offset=" +
            offset;
      if(this.a){
        url = this.domain +
            "evm/token_transfers/" +
            this.contract +
            "/address/"+this.a+"?limit=" +
            this.limit +
            "&offset=" +
            offset;
      }
      this.axios
        .get(url)
        .then((res) => {
          this.txs = res.data.data;
          this.total = res.data.pagination&&res.data.pagination.total ? res.data.pagination.total : (this.txs&&this.txs.length?this.txs.length:0);
          this.txLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.txs = null;
          this.txLoading = false;
        });
    },
  },
  components: {
    paginationView,
    TokenTransfer,
  },
  watch: {
    contract() {
      this.init();
    },
    a(){
      this.getTxs();
    }
  },
};
</script>
<style scoped>
.filter{
  border-bottom: var(--border);
  padding-bottom: 16px;
  margin-bottom: 24px;
}
.filter h3{
  padding-bottom: 8px;
  border: none;
}
.filter-tip{
  position: relative;
  cursor: pointer;
}
.filter-tip span{
  position: absolute;
  right: 100%;
  top: 50%;
  margin-top: -50%;
  margin-right: 4px;
  background: #f5f5f5;
  white-space: nowrap;
  color:var(--greyColor);
  padding: 4px 12px;
  border-radius: 4px;
  font: 400 12px/16px Gotham-Regular;
  display: none;
}
.filter-tip:hover span{
  display: block;
}
</style>
